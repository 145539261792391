import React, { useEffect } from "react";
import CaptainList from "./CaptainList";
import { addBackToTop } from "vanilla-back-to-top";

const Captain = ({ isLoggedIn, deleteActiveStatus, onCaptainDeletedStatus }) => {

  useEffect(() => {
    // Add to Top once
    addBackToTop();
  }, []);
  return (
    <CaptainList
      isLoggedIn={isLoggedIn}
      onCaptainDeletedStatus={onCaptainDeletedStatus}
      deleteActiveStatus={deleteActiveStatus}
    />
  );
};

export default Captain;
