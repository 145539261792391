// import { captains } from "../../../public/data";
import { getSundays, dateInfo, fetchLocally, checkIsDatePassed, isTodaySunday } from "../utlity";
import { CAPTAIN_STATUS } from "../utlity/constants";

function getMarkSundaysForCaptain(captains) {
  let sundaysPointer = 0;
  const sundays = getSundays();
  const result = [];

  // console.log("get mark sunday function ", {
  //   caplen: captains.length,
  //   sunlen: sundays.length,
  //   sundays,
  //   captains
  // });

  for (
    let i = 0;
    i < sundays.length && captains.length > sundays.length;
    i += 1
  ) {
    if (
      i === sundaysPointer &&
      sundays[sundaysPointer] !== undefined &&
      sundays[i] >= dateInfo().dateNo
    ) {
      result.push(sundays[sundaysPointer], sundays[sundaysPointer]); //for double entry//old line code 
      // result.push(sundays[sundaysPointer]);
    }

    sundaysPointer += 1;
    // console.log(i, sundaysPointer, captains[i], captains[i+1], sundays[i], sundays[i]);
  }

  for (
    let i = 0;
    i < sundays.length && captains.length <= sundays.length;
    i += 1
  ) {
    // console.log('hellow')
    if (sundays[i] > dateInfo().dateNo) {
      result.push(sundays[i], sundays[i]); //for double entry - old line code 
      // result.push(sundays[i]);

    }
    sundaysPointer += 1;
    // console.log(i, sundaysPointer, captains[i], captains[i+1], sundays[i], sundays[i]);
  }
  // console.log('getMarkSundaysForCaptain', { result })
  return result;
}

const captains = [];
export const captainsEffect = () => {
  return captains.length
    ? [...captains]
    : fetchLocally("ashootrux-c").length
      ? [...fetchLocally("ashootrux-c")]
      : [];
};

export const transformCaptains = (captains) => {

  if (!captains.length) return null;

  const tempCaptains = [...captains];

  // console.log("tempCaptains  ", tempCaptains);

  // const alreadyCaptains = tempCaptains.filter(
  //   (captain) => captain.already === CAPTAIN_STATUS["ALREADY"]
  // ).map(captain => {
  //   captain['captainStatus'] = 'already';
  //   return captain;
  // });

  // let tobeCaptains = tempCaptains
  //   .filter((captain) => !captain.already)
  //   .map(captain => {
  //     captain['captainStatus'] = 'tobe';
  //     return captain;
  //   })
  //   // .sort((a, b) => parseInt(dateInfo(b.onDate).dateNo) > parseInt(dateInfo(a.onDate).dateNo) ? -1 : 1 );
  //   .sort((a, b) => (b.name > a.name ? -1 : 1));

  const reformCaptinsList = tempCaptains.map(captainOld => {
    // console.log('captain on date value', captain, typeof captain.onDate);
    const captain = Object.assign({}, captainOld);
    if (captain.already) {
      captain['captainStatus'] = 'already';
      return captain;
    } else if (!captain.already && !checkIsDatePassed(captain.onDate)) {
      captain['onDate'] = "";
      captain['captainStatus'] = 'tobe';
      return captain;
    } else if (!captain.already && checkIsDatePassed(captain.onDate)) {
      captain['captainStatus'] = 'datePassed';
      return captain;
    }
  }).sort((a, b) => (b.name > a.name ? -1 : 1));
  // console.log("reformCaptinsList   ", reformCaptinsList);

  // let tobeCaptains = tempCaptains
  //   .filter((captain) => !captain.already)
  //   // .sort((a, b) => parseInt(dateInfo(b.onDate).dateNo) > parseInt(dateInfo(a.onDate).dateNo) ? -1 : 1 );
  //   .sort((a, b) => (b.name > a.name ? -1 : 1));

  // if (tobeCaptains.length === 0) {
  //   alreadyCaptains.forEach((cap) => (cap.already = CAPTAIN_STATUS["NEXT"]));
  //   tobeCaptains = [...alreadyCaptains];
  // }

  // console.log("tranform captains ", tobeCaptains, alreadyCaptains);

  const newCaptainsList = [...reformCaptinsList];
  const markSundaysForCaptain = getMarkSundaysForCaptain(newCaptainsList);

  // console.log('markSundaysForCaptain', markSundaysForCaptain);

  // dateInfo());

  // reformCaptinsList.forEach((captain, i) => {
  //   const check2 =
  //     parseInt(dateInfo(captain.onDate).dateNo) <
  //     parseInt(dateInfo(new Date()).dateNo);

  //   reformCaptinsList[i] = {
  //     ...captain,
  //     status: !check2 ? "" : "strike"
  //   };
  // });

  // console.log({ markSundaysForCaptain });

  // if (markSundaysForCaptain.length) {
  //   for (let i = 0; i < reformCaptinsList.length; i++) {
  //     if (reformCaptinsList[i]) {
  //       // console.log(
  //       //   markSundaysForCaptain[i],
  //       //   tobeCaptains[i].onDate,
  //       //   dateInfo(tobeCaptains[i].onDate).dateNo
  //       // );

  //       // console.log(
  //       //   i
  //       // );

  //       if (reformCaptinsList[i].onDate === " " || reformCaptinsList[i].onDate === "" || reformCaptinsList[i].onDate === null) {
  //         console.log('to mark upcoming captains!');
  //         reformCaptinsList[i]['captainStatus'] = "Upcoming";
  //         reformCaptinsList[i].onDate = new Date(2021, 3, markSundaysForCaptain[i]);
  //       } else {
  //         const check1 =
  //           parseInt(markSundaysForCaptain[i]) >=
  //           parseInt(dateInfo(reformCaptinsList[i].onDate).dateNo);

  //         const diffDate =
  //           parseInt(markSundaysForCaptain[i]) -
  //           parseInt(dateInfo(reformCaptinsList[i].onDate).dateNo);

  //         const isToday =
  //           parseInt(markSundaysForCaptain[i]) ===
  //           parseInt(dateInfo(new Date()).dateNo);

  //         // console.log({ isToday });
  //         // reformCaptinsList[i]['captainStatus'] = check1 ? "Upcoming" : "Upcoming";
  //         // reformCaptinsList[i]['captainStatus'] = diffDate <= 5 ? "Next" : "strike";
  //         // status = check2 ? "strike" : "";

  //         if (isToday) {
  //           reformCaptinsList[i]['captainStatus'] = "today";
  //         }
  //       }

  //       // console.log({ status });

  //       // reformCaptinsList[i] = {
  //       //   ...reformCaptinsList[i],
  //       //   id: parseInt(reformCaptinsList[i].id),
  //       //   name: reformCaptinsList[i].name,
  //       //   already: reformCaptinsList[i].already,
  //       //   onDate: reformCaptinsList[i].onDate,
  //       //   status
  //       //   // onDate: markSundaysForCaptain[i] + " " + dateInfo().labelMonth
  //       // };

  //     }
  //   }
  // }

  // console.log({ reformCaptinsList, tobeCaptains});
  // console.log({ dateInfo: dateInfo() });

  const resultCaptains = {
    totalCaptains: tempCaptains.length,
    alreadyCaptain: reformCaptinsList.filter(c => c.captainStatus === 'already'),
    datePassedCaptain: reformCaptinsList.filter(c => c.captainStatus === 'datePassed'),
    todayCaptain: reformCaptinsList.filter(c => c.captainStatus === 'today'),
    upcomingCaptain: reformCaptinsList.filter(c => c.captainStatus === 'upcoming'),
    tobeCaptain: reformCaptinsList.filter(c => c.captainStatus === 'tobe'),
  };
  // console.log("transform captains list", { resultCaptains });


  // formUpcomingCaptains from tobeCaptain

  if (resultCaptains.tobeCaptain.length) {
    const maxSpliceValue = resultCaptains.tobeCaptain.length > 4 ? markSundaysForCaptain.length < 4 || markSundaysForCaptain.length > 4 ?
      markSundaysForCaptain.length : 4 : resultCaptains.tobeCaptain.length; //markSundaysForCaptain.length;
    const upcomingCaptainsList = resultCaptains.tobeCaptain.splice(0, maxSpliceValue);
    // console.log({ upcomingCaptainsList, resultCaptains });
    resultCaptains['upcomingCaptain'] = upcomingCaptainsList.map((captain, idx) => {
      captain['captainStatus'] = "upcoming";
      const d = new Date();
      captain['onDate'] = new Date(d.getFullYear(), d.getMonth(), markSundaysForCaptain[idx]).toString();

      return captain;
    });

  }

  if (resultCaptains.todayCaptain.length === 0 && resultCaptains.upcomingCaptain.length &&
    isTodaySunday(markSundaysForCaptain)) {
    const todayCaptainsList = resultCaptains.upcomingCaptain.splice(0, 2);

    resultCaptains['todayCaptain'] = todayCaptainsList.map((captain, idx) => {
      captain['captainStatus'] = "today";
      captain['onDate'] = new Date().toString();

      return captain;
    });

  }

  // console.log(resultCaptains);

  return resultCaptains;
};

export const addCaptainService = (captain) => {
  // console.log({ captain });

  if (captain) {
    const localData = fetchLocally("ashootrux-c") || [];
    const localDataLen = localData.length;
    const updateCaptains = [...localData, addNewCaptain(captain)];

    // console.log({ updateCaptains });

    if (updateCaptains.length === localDataLen + 1) {
      transformCaptains(updateCaptains);
      // saveLocally("ashootrux-c", updateCaptains);
      alert(`Captain ${captain.name} added successfully`);
      return true;
    }
  }
  return false;
};

function addNewCaptain(captain) {
  return {
    name: captain,
    onDate: null,
    already: false
  };
}

export const deleteCaptainService = (captain) => {
  // console.log({ captain });

  if (captain) {
    const localData = fetchLocally("ashootrux-c") || [];
    const updateCaptains = localData.filter(
      (cap) => cap.name.toLowerCase() !== captain.name.toLowerCase()
    );
    // console.log("deleted filtered captains ", { updateCaptains });
    // saveLocally("ashootrux-c", updateCaptains);

    return updateCaptains;
  }
};
