

const captainDay = {
  "sunday": 0,
  "monday": 1,
  "tuesday": 2,
  "wednesday": 3,
  "thursday": 4,
  "friday": 5,
  "saturday": 6,
};
export const URI = {
  CUSTOM_APOLLO_SERVER_URI: "https://ox8qt.sse.codesandbox.io"
};

export const TC_BASE_URL = "https://doableyo.com/team-captain";
export const ABSOLUTE_ASSETS_URL = "https://doableyo.com/tc-assets/images";
export const CAPTAIN_STATUS = {
  ALREADY: true,
  UPCOMING: 2,
  NEXT: 3
};

export const LSKey = {
  isLoggedIn: "isLoggedIn",
  isDBUPDATE: "isTeamCaptainDBAlreadyUpdate",
  ON_SUNDAY_DB_CLEAR_ONCE: "isSundayClearDBUpdateOnce"
};
export const CONSTANTS = {
  SITE_NAME: "Team Captain",
  CAPTAIN_DAY: captainDay['sunday']
}