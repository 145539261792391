import React from "react";

function Loader({ show, type, spinnerType, loaderStyle, message, position }) {
  //SpinnerType available [primary, secondary, success, danger, warning, info, light, dark];

  if (!show) return null;

  if (type && type === "inplace") {
    return (
      <>
        <div className="d-flex justify-content-center">
          {loaderStyle && loaderStyle === 'dots' ? (<div
            className={`loaderInplace ${loaderStyle.toLowerCase()}`}
            role="status"
          >
          </div>) : (<div
            className={`spinner-border ${spinnerType ? "text-" + spinnerType : "text-info"
              } `}
            role="status"
          >
          </div>)}
        </div>
        {message && <span className="text-center d-block text-dark">{message}</span>}
      </>
    );
  }

  return (
    <div className={`loaderWrapper ${position === 'absolute' ? 'position-' + position : ''}`} style={{ zIndex: 1 }}>
      <div className={`loader ${position === 'absolute' ? 'position-static' : ''}`}>
        <p className="text-dark">{message || ""}</p>
      </div>
    </div >
  );
}

export default Loader;
