import React from "react";
import { CONSTANTS as C, ABSOLUTE_ASSETS_URL } from "../utlity/constants";

const Footer = ({ }) => {
  return (
    <>
      <footer className="footer text-center">
        <p>Thank you for visiting.</p>

        <p>
          &copy; {C.SITE_NAME} {new Date().getFullYear()}. All rights reserved.{" "}
        </p>
        <div className="clearfix footer-partner-block">
          <div className="float-end1 py-2">
            <a href="https://uidevwork.doableyo.com/" target="_blank" title="UDW-UIDevWork">
              <img src={`${ABSOLUTE_ASSETS_URL}/udw-partner-logo.svg`}
                height={75}
                alt={'udw-partner'}
              />
              <small className="text-muted d-block">Development Design Partner</small>
            </a>
          </div>

        </div>
      </footer>
    </>
  );
};

export default Footer;
