import React from "react";
import { Link } from "react-router-dom";

import { Chip } from "@material-ui/core";

import { dateInfo, readableFormat } from "../../utlity";
import { ABSOLUTE_ASSETS_URL, TC_BASE_URL } from "../../utlity/constants";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

const SectionTemplate = ({ captainData, title, classSuffix, handleToggle, isLoggedIn, deleteActiveStatus,
    handleDelete }) => {

    // console.log({ captainData });
    // if (!captainData.length) return ("No captain found");

    return (
        <>
            <div className="container m-0 p-0"><h2 className="display-4">{title}</h2></div>
            {captainData.length === 0 ? (
                <div className="col-12 col-md-12 mb-5 px-0 captain-section-tile"
                >
                    <div
                        className="p-3 bg-white text-center"
                    >
                        No captain found!
                    </div>
                </div>
            ) : (

                <section className={`captain-section section-${classSuffix}`}>
                    <div className="container m-0 p-0">
                        <div className="row">

                            {captainData.length !== 0 && captainData.map((captain, idx) => {
                                const labelId = `checkbox-list-label-${captain.name}`;

                                const conditionalChipUpcomingHighlightColor =
                                    captain.captainStatus === "upcoming" &&
                                        parseInt(captain.onDate) - parseInt(dateInfo().dateNo) <= 5
                                        ? "secondary"
                                        : "primary";

                                return (
                                    <div className={`col-12 col-md-3 captain-section-tile ${isLoggedIn ? 'loggedIn-active ' : ''}`}
                                        key={"list" + idx}
                                    >
                                        <div
                                            className={`card rounded-0 border-0 cursor-pointer`}
                                            // style={{ width: "18rem" }}
                                            onClick={() => handleToggle(captain.name)}
                                        >
                                            {isLoggedIn ? (
                                                <Link
                                                    to={{
                                                        pathname: !deleteActiveStatus
                                                            ? `/edit/${captain.id}`
                                                            : "delete",
                                                        state: { captain: captain }
                                                    }}
                                                    style={{
                                                        display: "flex",
                                                        flex: "1",
                                                        textDecoration: "none",
                                                        color: "inherit"
                                                    }}
                                                    color="default"
                                                    className="avatar-wrap"
                                                >
                                                    <img
                                                        src={captain?.photo ? TC_BASE_URL + '/' + captain.photo : `${ABSOLUTE_ASSETS_URL}/tc-avatar.png`}
                                                        className=" avatar"
                                                        alt="tc-avatar"
                                                    />
                                                    {/* <PermIdentityIcon color={conditionalUpcomingHighlightColor} /> */}
                                                </Link>
                                            ) : (
                                                <>
                                                    <span className="avatar-wrap">
                                                        <img
                                                            src={captain?.photo ? TC_BASE_URL + '/' + captain.photo : `${ABSOLUTE_ASSETS_URL}/tc-avatar.png`}
                                                            className="avatar"
                                                            alt="tc-avatar"
                                                        />
                                                    </span>
                                                </>
                                            )}

                                            <div className="card-body">
                                                <h5 className="card-title text-capitalize"
                                                    id={labelId}
                                                >{`${captain.name}`}</h5>
                                                <p className="card-text">{captain.description || `He loves to play cricket on sunday's!`}</p>

                                                {/* {deleteActiveStatus + " " + captain.captainStatus} */}
                                                <>
                                                    {isLoggedIn && (
                                                        <>
                                                            <span
                                                                className="fs-3 text-capitalize px-3 py-1 mr-2 rounded-pill 
                                                                btn btn-sm btn-outline-primary"
                                                            >
                                                                {captain.onDate ?
                                                                    <>On date - {readableFormat(captain.onDate)}</> : (
                                                                        <>No onDate set</>
                                                                    )}
                                                            </span>
                                                        </>
                                                    )}
                                                    {!deleteActiveStatus && captain.captainStatus === 'today' && (
                                                        <>
                                                            <span
                                                                className="fs-3 text-capitalize px-3 py-2 mr-2 rounded-pill 
                                                                btn btn-sm btn-outline-success"
                                                            >{captain.captainStatus} - {readableFormat(captain.onDate)}</span>

                                                        </>
                                                    )}
                                                    {!deleteActiveStatus && captain.captainStatus === 'upcoming' && (
                                                        <>
                                                            {/* <Chip
                                                                style={{ marginRight: ".4rem" }}
                                                                variant="outlined"
                                                                size="small"
                                                                color={conditionalChipUpcomingHighlightColor}
                                                                label={captain.captainStatus + ' - ' + readableFormat(captain.onDate)}
                                                                className="text-capitalize"
                                                            /> */}

                                                            <span
                                                                className="fs-3 text-capitalize px-3 mr-2 rounded-pill 
                                                                btn btn-sm btn-outline-dark"
                                                            >{captain.captainStatus} - {readableFormat(captain.onDate)}</span>

                                                            {/* <Chip
                                                            variant="outlined"
                                                            color={conditionalChipUpcomingHighlightColor}
                                                            size="small"
                                                            label={readableFormat(captain.onDate)}
                                                        /> */}
                                                        </>
                                                    )}

                                                    {captain.captainStatus === 'tobe' && (
                                                        <>
                                                            {/* <Chip
                                                            variant="outlined"
                                                            size="small"
                                                            label={"Yet to be"}
                                                        /> */}
                                                            <span
                                                                className="text-capitalize px-3 mr-2 rounded-pill 
                                                                btn btn-sm btn-outline-light"
                                                            >Yet to be</span>

                                                        </>
                                                    )}
                                                    {!deleteActiveStatus
                                                        && captain?.captainStatus === 'datePassed' && (<>

                                                            <del><Chip
                                                                variant="outlined"
                                                                size="small"
                                                                label={readableFormat(captain.onDate)}
                                                            />
                                                            </del>{" "}
                                                            <Chip
                                                                style={{ marginRight: ".4rem" }}
                                                                variant="outlined"
                                                                size="small"
                                                                label={`Date Passed`}
                                                            />
                                                        </>)
                                                    }

                                                    {!deleteActiveStatus && captain.captainStatus === 'already' &&
                                                        (
                                                            <>
                                                                <Chip
                                                                    style={{ marginRight: ".4rem" }}
                                                                    variant="outlined"
                                                                    size="small"
                                                                    color="default"
                                                                    label={captain.captainStatus}
                                                                    className="text-capitalize"
                                                                    disabled={true}
                                                                />
                                                                <Chip
                                                                    variant="outlined"
                                                                    size="small"
                                                                    color="default"
                                                                    disabled={true}
                                                                    label={readableFormat(captain.onDate)}
                                                                />
                                                            </>
                                                        )}
                                                    {deleteActiveStatus && (
                                                        // <Button
                                                        //   m={1}
                                                        //   variant="contained"
                                                        //   size="small"
                                                        //   color="secondary"
                                                        //   onClick={() => handleDelete(value)}
                                                        //   startIcon={<DeleteIcon />}
                                                        // >
                                                        //   Delete
                                                        // </Button>
                                                        <DeleteOutlinedIcon
                                                            color="secondary"
                                                            onClick={() => handleDelete(value)}
                                                        />
                                                    )}
                                                </>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </section>
            )}
        </>
    );
};

export default SectionTemplate;

