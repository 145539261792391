import React from "react";
import Logo from "./Logo";
import {
  Route,
  Link
} from "react-router-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";

const Header = ({ isLoggedIn, handleLogout }) => {
  return (
    <header className="header sticky-top d-flex align-items-center justify-content-between">
      <Logo />
      <div className="top-navigation float-end">

        <DropdownButton size="sm" variant="dark" align="end" title="Admin" id="dropdown-menu-align-end">
          {isLoggedIn ? (
            <Dropdown.Item eventKey="1"
              variant="dark" size="sm" onClick={() => handleLogout()}
            >Logout</Dropdown.Item>
          ) : (
            <Dropdown.Item
              variant="dark"
              size="sm"
              eventKey="2"><Route
                exact
                path={`/`}
                component={() => <Link to={"/login"}
                  className="">Login</Link>}
              /></Dropdown.Item>
          )}
        </DropdownButton>

        {/* {isLoggedIn ? (
              <a onClick={() => handleLogout()}
                className="text-white dropdown-item">Logout</a>
            ) : (
              <Route
                exact
                path={`/`}
                component={() => <Link to={"/login"}
                  className="text-white dropdown-item">Login</Link>}
              />
            )} */}

      </div>
    </header >
  );
};

export default Header;
