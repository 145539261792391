import "date-fns";
import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Backdrop,
  FormControl,
  FormControlLabel,
  InputAdornment,
  makeStyles,
  TextField,
  Switch,
  Snackbar,
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import CircularProgress from '@material-ui/core/CircularProgress';
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

// import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
// import { addCaptain } from "../../service/captain.service";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { MUTATUON_ADD_CAPTAIN } from "../../service/graphql-app-crud";
// import { addCaptainService } from "../../service/captain.service";

import MuiAlert from '@material-ui/lab/Alert';
import Back from "./Back";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "5px"
  }
}));


const AddCaptain = (props) => {
  const history = useHistory();
  const [addCaptain] = useMutation(MUTATUON_ADD_CAPTAIN, {
    fetchPolicy: "no-cache"
  });

  const [selectedDate, setSelectedDate] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const [formState, setFormState] = useState({
    cName: "",
    already: false,
    status: "",
    onDate: selectedDate
  });

  const [formValidationError, setFormValidationError] = useState({
    name: {
      invalid: false,
      message: 'Please enter captain name'
    }
  })

  const classes = useStyles();
  const timeout = 0;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setFormValidationError({
      name: {
        ...formValidationError.name,
        invalid: false
      }
    });
  };

  const handleInput = (e) => {
    const { name, checked, value } = e.target;
    clearTimeout(timeout);
    // console.log({target: e.target, name, checked, value });

    if (!name) return;

    setTimeout(() => {
      if (name === "already") {
        // console.log("if already field clicked");
        setFormState({
          ...formState,
          onDate: checked === false ? null : formState["onDate"],
          [name]: checked
        });
      } else {
        // console.log('value ', value);
        if (value !== "") {
          // console.log("if not already value not empty field clicked");
          setFormState({ ...formState, [name]: value });
          // console.log("222", formState );
        }
      }

    }, timeout);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setFormState({ ...formState, onDate: date });
  };

  const save = (captain) => {
    // console.log("save", captain);

    if (captain.cName === "") {
      // alert("Please enter captain name");
      setFormValidationError({
        name: {
          ...formValidationError.name,
          invalid: true
        }
      });
      setIsSaving(false);
      return;
    }

    // const { monthLabel, dateNo } = dateInfo(captain.onDate);

    setIsSaving(true);
    const addStatus = addCaptain({
      variables: {
        name: captain.cName,
        already: captain.already,
        onDate: captain.onDate //`${dateNo} ${monthLabel}`
      }
    }).then((response) => {
      const { data } = response;
      // addCaptainService(data.addCaptain);
      // alert(`Captain ${captain.cName} added successfully`);
      if (
        data.addCaptain &&
        (data.addCaptain.id !== null || data.addCaptain.id === "")
      ) {
        history.push("/");
      }
    });
    // console.log("handle Add ", addStatus);

    if (addStatus) {
      props.onCaptainAddedStatus(addStatus);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    save(formState);
  };

  useEffect(() => {
    // console.log("111", formState );
  });

  return (
    <>
      <section className="section-common section-add w-50 mx-auto">
        <h3>Add New Captain!</h3>

        {formValidationError.name.invalid ? (
          <>
            {/* <Snackbar open={true} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            This is a success message!
          </Alert>
        </Snackbar>
        <Alert severity="error">This is an error message!</Alert>
        <Alert severity="warning">This is a warning message!</Alert>
        <Alert severity="info">This is an information message!</Alert>
        <Alert severity="success">This is a success message!</Alert> */}

            <Snackbar
              autoHideDuration={2000}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={true}
              key={'fieldError' + 1}
              onClose={handleClose}>
              <Alert onClose={handleClose} severity="error">{formValidationError.name.message}</Alert>
            </Snackbar>
          </>) : null}

        {isSaving ? (
          <Backdrop className={classes.backdrop} open={true} onClick={handleClose}>
            <CircularProgress color="inherit" />
          </Backdrop>) : null}

        <FormControl
          component="fieldset"
          className="w-100 mt-3"
        >
          <form noValidate autoComplete="off" onSubmit={handleSubmit}
            className="w-100"
          >

            <div className="form-group">
              <TextField
                autoFocus
                required
                name="cName"
                id="cName"
                label="Name"
                color="primary"
                defaultValue={formState.cName}
                className="w-100"
                onChange={(e) => handleInput(e)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  )
                }}
              />
            </div>

            {/* {'formState.already ' + formState.already} */}

            <div className="form-group">
              <FormControlLabel
                control={
                  <Switch
                    checked={formState.already}
                    onChange={handleInput}
                    defaultValue={formState.already}
                    id="already"
                    name="already"
                    color="primary"
                  />
                }
                label="Already been a captain?"
              />
            </div>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                variant="dialog"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="On Date"
                name="onDate"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
                className="w-100"
              />
            </MuiPickersUtilsProvider>

            {/* {formState.already ? (
          ) : null} */}

            <div className="form-group mt-4 text-center">
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                size="small"
                className={classes.button}
                startIcon={<SaveOutlinedIcon />}
              >
                Save
              </Button>
            </div>
          </form>


        </FormControl>
      </section>
      <Back />
    </>
  );
};

export default AddCaptain;
