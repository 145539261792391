import { gql } from "@apollo/client";

export const QUERY_CAPTAINS = gql`
  query {
    captains {
      id
      name
      onDate
      already
      description
      photo
    }
  }
`;

export const MUTATUON_ADD_CAPTAIN = gql`
  mutation AddCaptain($name: String!, $onDate: String, $already: Boolean!) {
    addCaptain(captain: { name: $name, onDate: $onDate, already: $already }) {
      id
      name
    }
  }
`;

export const MUTATUON_UPDATE_CAPTAIN = gql`
  mutation UpdateCaptain($captain: UpdateCaptain!) {
    updateCaptain(
      captain: $captain
    ) {
      id
      name
      onDate
      already
      description
    }
  }
`;

export const MUTATUON_UPLOAD_IMAGE = gql`
  mutation UploadImage($uploadImage: UploadImageInput!) {
    uploadCaptainImage(uploadImage: $uploadImage) {
      message
      success
    }
  }
`;

export const DELETE_CAPTAIN = gql`
  mutation DeleteCaptain($id: ID!) {
    deleteCaptain(captain: { id: $id }) {
      message
    }
  }
`;
