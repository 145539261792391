import React from "react";
import { Link } from "react-router-dom";
import SportsCricketOutlinedIcon from "@material-ui/icons/SportsCricketOutlined";

const Logo = () => {
  return (
    <h1 className="logo">
      <Link to="/" style={{ textDecoration: "none" }}>
        <SportsCricketOutlinedIcon fontSize="large" /> Team Captain
      </Link>
    </h1>
  );
};

export default Logo;
