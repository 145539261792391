import "date-fns";
import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import {
  Button,
  FormGroup,
  FormControl,
  FormControlLabel,
  InputAdornment,
  makeStyles,
  TextField,
  Switch,
  Chip
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

// import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
// import { addCaptain } from "../../service/captain.service";
import { useMutation } from "@apollo/client";
import { Link, useHistory } from "react-router-dom";
import { dateInfo } from "../../utlity";
import { MUTATUON_UPDATE_CAPTAIN } from "../../service/graphql-app-crud";
import Back from "./Back";
import UploadImage from "../UploadImage";
import { toast } from "react-toastify";
import { ABSOLUTE_ASSETS_URL, TC_BASE_URL } from "../../utlity/constants";
import { DescriptionRounded } from "@material-ui/icons";
import Loader from "../Loader";
// import { addCaptainService } from "../../service/captain.service";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "5px"
  }
}));


const EditCaptain = () => {
  const history = useHistory();
  const [updateCaptain] = useMutation(MUTATUON_UPDATE_CAPTAIN, {
    // fetchPolicy: "no-cache"
  });

  const classes = useStyles();

  const currentCaptain =
    history.location.state && history.location.state.captain
      ? Object.assign({}, history.location.state.captain)
      : null;

  if (!currentCaptain)
    return (
      <>
        <Box m={1} display="flex" justifyContent="center">
          No captain found, please try another captain!
        </Box>

        <Link to="/">Back to list</Link>
      </>
    );

  // const [selectedDate, setSelectedDate] = useState(new Date(currentCaptain.onDate));
  const defaultOnDate = currentCaptain.onDate === "" || !currentCaptain.onDate ? null : currentCaptain.onDate;
  const [selectedDate, setSelectedDate] = useState(defaultOnDate);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // console.log({ currentCaptain });

  const [formState, setFormState] = useState({
    ...currentCaptain
  });

  const timeout = 0;

  const handleInput = (e) => {
    const { name, checked, value } = e.target;
    clearTimeout(timeout);
    // console.log({ name, checked, value });

    if (!name) return;

    setTimeout(() => {
      if (name === "already") {
        setFormState({ ...formState, [name]: checked });
      } else {
        if (value !== "") {
          setFormState({ ...formState, [name]: value });
        }
      }
    }, timeout);

    // console.log(formState);
  };

  const handleDateChange = (newDate) => {
    // console.log(date);
    const date = newDate;
    setSelectedDate(date);
    setFormState({ ...formState, onDate: date });
  };

  const save = (captain) => {

    // console.log('save update', selectedDate, typeof captain.onDate, typeof selectedDate);

    if (captain.name === "") {
      alert("Please enter captain name");
      return;
    }

    // const { monthLabel, dateNo } = dateInfo(captain.onDate);
    setIsSubmitting(true);
    const payload = {
      id: captain.id,
      name: captain.name,
      onDate: selectedDate, //`${dateNo} ${monthLabel}`
      already: captain.already,
      description: captain.description,
    };
    // console.log({ payload });
    updateCaptain({
      variables: {
        captain: payload
      }
    }).then((response) => {
      const { data } = response;
      setIsSubmitting(false);
      // console.log(response);
      if (
        data.updateCaptain &&
        (data.updateCaptain.id !== null || data.updateCaptain.id === "")
      ) {

        toast("Captain " + data?.updateCaptain?.name + " updated successfully!");
        history.push({
          pathname: "/",
          state: { refresh: true, updated: true }
        });
      }
    }).catch((err) => {
      setIsSubmitting(false);
      console.log('Error', new Error(err));
    });

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    save(formState);
  };

  const handleUploadPhotoSuccess = () => {
    // toast("Photo updated successfully!");
  }

  // console.log({ formState });

  return (
    <>
      {isSubmitting && <Loader show={isSubmitting} />}
      <section className="section-common section-add w-50 mx-auto">
        <h3>Edit Captain!</h3>
        <FormControl
          component="fieldset"
          className="w-100 mt-3"
        >
          <>
            {formState.status === "Upcoming" ? (
              <Box>
                <Chip
                  label={"Upcoming Captain"}
                  size="small"
                  color="secondary"
                  variant="default"
                />
              </Box>
            ) : null}

            <form noValidate autoComplete="off" onSubmit={(e) => handleSubmit(e)}
              className="w-100"
            >
              <div className="form-group">
                <TextField
                  required
                  name="name"
                  id="name"
                  label="Name"
                  color="primary"
                  defaultValue={formState.name}
                  onChange={(e) => handleInput(e)}
                  className="w-100"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    )
                  }}
                />
              </div>

              <div className="form-group">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formState.already}
                        onChange={handleInput}
                        name="already"
                        color="primary"
                      />
                    }
                    label="Already been a captain?"
                    className="w-100"
                  />
                </FormGroup>
              </div>

              <div className="form-group app-datepicker clearfix">
                <MuiPickersUtilsProvider utils={DateFnsUtils}
                  className="">
                  <KeyboardDatePicker
                    variant="dialog"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="On Date"
                    name="onDate"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                    className="w-100 mt-0"
                  />
                  <a href="#" className="float-right" onClick={(e) => {
                    e.preventDefault();
                    setSelectedDate(null);
                  }}>
                    <small>clear date</small></a>
                </MuiPickersUtilsProvider>
              </div>

              <div className="form-group">
                <TextField
                  required
                  name="description"
                  id="description"
                  label="Description"
                  color="primary"
                  defaultValue={formState.description}
                  onChange={(e) => handleInput(e)}
                  className="w-100"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DescriptionRounded />
                      </InputAdornment>
                    )
                  }}
                />
              </div>

              <div className="form-group">
                <UploadImage label="Upload captain photo"
                  id={formState.id}
                  previewImage={formState?.photo ? TC_BASE_URL + '/' + formState.photo : `${ABSOLUTE_ASSETS_URL}/tc-avatar.png`}
                  onUploadSuccess={() => handleUploadPhotoSuccess()} />
              </div>


              <div className="form-group mt-4 text-center">
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  size="small"
                  className={classes.button}
                  startIcon={<SaveOutlinedIcon />}
                >{isSubmitting ? 'Saving...' : 'Save'}
                </Button>
              </div>
            </form>
          </>
        </FormControl>
      </section>

      <Back />

    </>
  );
};

export default EditCaptain;
