import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
  // useHistory
} from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Button } from "@material-ui/core";
// import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Captain from "./components/captain";
import AddCaptain from "./components/captain/AddCaptain";
import EditCaptain from "./components/captain/EditCaptain";
import Reset from "./components/Reset";
import CaptainCarousel from "./components/Carousel";
import Login from "./components/Login";
// import { LSKey } from "./utlity/constants";
import { loginCheck, logout } from "./utlity";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { ToastContainer, Zoom } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  root: {
    // fontFamily: "Pacifico, cursive",
    width: "100%",
    // maxWidth: 560,
    margin: "0 auto"
  }
}));

export default function App(props) {
  // const history = useHistory();
  const classes = useStyles();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [layout, setLayout] = useState("list");
  const [deletionActive, setDeletionActive] = useState(false);

  const captainAddedStatus = (status) => {
    // console.log("captainAddStatus invoke at parent");
    if (status) {
      setLayout("list");
    }
  };

  const captainDeletedStatus = (status) => {
    // console.log("captainAddStatus invoke at parent");
    if (status) {
      setDeletionActive(false);
      setLayout("list");
    }
  };

  const handleBack = () => {
    setLayout("list");
  };

  const handleLogout = () => {
    if (logout()) {
      const loginCheckStatus = loginCheck();
      // console.log("loginCheck ", loginCheckStatus);

      if (!loginCheckStatus) setIsLoggedIn(false);
      // history.push("/");
      window.location.href = "/";
    }
  };

  // console.log("props app ", props);
  // console.log("history app ", history);

  useEffect(() => {
    const loginCheckStatus = loginCheck();
    // console.log("loginCheck ", loginCheckStatus);

    if (loginCheckStatus) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  return (
    <Router>
      <div className="App">
        <Header isLoggedIn={isLoggedIn} handleLogout={handleLogout} />

        <div className={` ` + classes.root}>
          <div className="main-content-view">
            <div className="container">

              {!isLoggedIn ? (
                <div
                  style={{
                    // overflow: "hidden",
                    border: "1 solid red",
                    height: "100%"
                    // marginBottom: "-48px"
                  }}
                >
                  {/* Carpousel implementation here! */}
                  {/* <CaptainCarousel /> */}
                </div>
              ) : null}

              <Switch>
                <Route exact path="/">
                  <Captain isLoggedIn={isLoggedIn}
                    onCaptainDeletedStatus={captainDeletedStatus}
                    deleteActiveStatus={false} />
                </Route>
                <Route path="/add">
                  <AddCaptain
                    isLoggedIn={isLoggedIn}
                    onCaptainAddedStatus={captainAddedStatus}
                  />
                </Route>
                <Route path="/delete">
                  <Captain
                    isLoggedIn={isLoggedIn}
                    onCaptainDeletedStatus={captainDeletedStatus}
                    deleteActiveStatus={true}
                  />
                </Route>
                <Route path="/delete:id">
                  <Captain
                    isLoggedIn={isLoggedIn}
                    onCaptainDeletedStatus={captainDeletedStatus}
                    deleteActiveStatus={deletionActive}
                  />
                </Route>
                <Route path="/edit/:id">
                  <EditCaptain isLoggedIn={isLoggedIn} />
                </Route>
                <Route path="/reset">
                  <Reset />
                </Route>
                <Route path="/login">
                  <Login />
                </Route>
              </Switch>
            </div>
            {/* Back Component */}
            {layout === "add" || layout === "delete" ? (
              <Box display="flex" justifyContent="flex-end">
                <Button
                  onClick={() => handleBack()}
                  variant="contained"
                  color="action"
                  size="small"
                  className="d-flex align-self-start"
                >
                  Back
                  {/* <ArrowBackIcon size="small" />  Back */}
                </Button>
              </Box>
            ) : null}


          </div>
        </div>

      </div>

      <Route exact path={`/`} component={() => <Footer {...props} />} />
      <ToastContainer
        delay={3000}
        autoClose={2400}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        transition={Zoom}

      />
    </Router>
  );
}
