import React from 'react';
import { Link } from 'react-router-dom';

import {
  Box,
} from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const Back = () => {
  return (<Box p={2} display="flex" justifyContent="center">
    <Link to="/" className="btn btn-primary">
      <ArrowBackIcon fontSize="small" />
      {/* Back */}
    </Link>
  </Box>)

}

export default Back;