import { CONSTANTS as C, LSKey } from "./constants";

export const fetchLocally = (name = "ashootrux-c") => {
  const localCaptains = localStorage.getItem(name);

  if (localCaptains !== "null") {
    return JSON.parse(localCaptains);
  }
  return null;
};

export const saveLocally = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const dateInfo = (param = new Date()) => {
  // console.log({param});

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];

  const date = typeof param === "string" ? new Date(param) : param;
  // console.log(date);

  const month = date.getUTCMonth();
  const year = date.getUTCFullYear();
  const dateNo = date.getDate();
  const day = date.getUTCDay();

  const result = {
    year,
    month,
    monthLabel: months[month],
    dateNo,
    day,
    labelMonth: months[month]
  };
  // console.log('date info result', result );
  return result;
};

export const readableFormat = (date) => {
  // console.log('readableFormat date', date);
  if (date) {
    const { monthLabel, dateNo } = dateInfo(new Date(date));
    // console.log({date, monthLabel, dateNo });
    return `${dateNo} ${monthLabel}`;
  }
};

export function getSundays() {
  let d = new Date();
  let pred = new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate(); //For current month sundays
  // let pred = new Date(d.getFullYear(), 11, 31).getDate(); // All Year Sundays

  let nowd;
  let sat = [];
  let sun = [];

  for (let i = 1; i <= pred; ++i) {
    nowd = new Date(d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + i);
    if (nowd.getUTCDay() === C.CAPTAIN_DAY) {
      sun = [...sun, i];
    } else if (nowd.getUTCDay() === 6) {
      sat = [...sat, i];
    }
  }

  // console.log(sun);
  return sun;
}

export const isTodaySunday = (daysArray) => daysArray.includes(new Date().getDate());


export const loginCheck = () => {
  // console.log("loginCheck loginCheck > ", fetchLocally(LSKey.isLoggedIn));
  return fetchLocally(LSKey.isLoggedIn);
};

export const saveLogin = () => {
  saveLocally(LSKey.isLoggedIn, true);
};

export const logout = () => {
  saveLocally(LSKey.isLoggedIn, false);
  // console.log("logout logout > ", fetchLocally(LSKey.isLoggedIn));
  return true;
};


export const checkIsClosedToday = (date) => {
  if (!date || date === '') {
    console.error('Error: checkIsClosedToday date is not defined or value undefined or empty found ', date);
    return;
  }

  const today = new Date();
  // const parsedDate = new Date(date);
  // const parsedDate = (date);
  // today.setHours(0, 0, 0, 0);
  const day = (today.getDate()) < 10 ? '0' + (today.getDate()) : (today.getDate());
  const month = (today.getMonth() + 1) < 10 ? '0' + (today.getMonth() + 1) : (today.getMonth() + 1);
  const year = today.getFullYear();

  const formedDate = `${year}-${month}-${day}`;
  // console.log({ formedDate, date });

  if (formedDate === date) return true;
  return false;
}

export const checkIsDatePassed = (date) => {
  // console.log({ date });
  if (!date || date === '') {
    // console.log('Error: checkIsDatePassed date is not defined or value undefined or empty found ', date);
    return false;
  }

  const today = new Date();
  const parsedDate = new Date(date);

  today.setHours(0, 0, 0, 0);

  // console.log({ parsedDate, today });

  if (parsedDate < today) return true;
  return false;
}

export const previewImageHandler = function (event, type) {

  if (type == 'image' && !document.getElementById('uploadingTobeText')) {
    console.warn('Image preview uploadingTobeText DOM element must be present in the DOM tree');
    return;
  }
  if (type == 'thumb' && !document.getElementById('uploadingTobeThumbText')) {
    console.warn('Image preview uploadingTobeThumbText DOM element must be present in the DOM tree');
    return;
  }

  var output = document.getElementById(type && type === 'image' ? 'outputImage' : 'outputThumb');
  output.src = URL.createObjectURL(event.target.files[0]);
  if (type && type === 'image' && document.getElementById('uploadingTobeText')) {
    document.getElementById('uploadingTobeText').style.display = 'inline-block';
  }

  if (type && type === 'thumb' && document.getElementById('uploadingTobeThumbText')) {
    document.getElementById('uploadingTobeThumbText').style.display = 'inline-block';
  }

  output.onload = function () {
    URL.revokeObjectURL(output.src) // free memory
  }
};
