import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { MUTATUON_UPLOAD_IMAGE } from '../service/graphql-app-crud';
import { previewImageHandler } from '../utlity';
import { ABSOLUTE_ASSETS_URL } from '../utlity/constants';
import Loader from './Loader';

function UploadImage({ id, label, optionalText, onUploadSuccess, previewImage }) {

    // console.log({ id, label, optionalText, onUploadSuccess });
    const [photo, setPhoto] = useState('');
    const [isUploaded, setIsUploaded] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [uploadCaptainImage] = useMutation(MUTATUON_UPLOAD_IMAGE, {
        fetchPolicy: "no-cache",
        onError: (error) => {
            console.error(error);
            setIsSubmitting(false);
        }
    });

    const onFileChange = (e) => {
        let files = e.target.files;
        let fileReader = new FileReader();
        fileReader.readAsDataURL(files[0]);

        fileReader.onload = (event) => {
            setPhoto(event.target.result);
        }

        previewImageHandler(e, 'image');
    }

    const handleReUpload = () => {
        // setPhoto('');
        setIsUploaded(false);
    }

    const onSubmit = () => {
        const payload = {
            id: id, //Need to make this dynamic
            photo,
        }

        setIsSubmitting(true);
        uploadCaptainImage({
            variables: {
                uploadImage: {
                    ...payload
                }
            }
        })
            .then((response) => {
                const { uploadCaptainImage } = response && response.data;
                console.log({ uploadCaptainImage });
                if (uploadCaptainImage.success) {
                    toast.success(uploadCaptainImage.message || "Photo uploaded successfully!");
                    setIsSubmitting(false);
                    setIsUploaded(true);
                    setPhoto(uploadCaptainImage.photo || previewImage);
                }
            }).catch((err) => {
                // setGqError(true);
                console.error(new Error(err));
                toast.error("Image uploading error occoured, please try again/later");
                setIsSubmitting(false);
                setIsUploaded(false);
            });

    }

    useEffect(() => {

        return () => {
            setIsSubmitting(false);
            setIsUploaded(false);
        }
    }, []);

    useEffect(() => {
        if (isUploaded) onUploadSuccess();
    }, [isUploaded]);

    if (isUploaded) return <p className="fs-4">
        {/* <span className="text-success d-block">Photo Uploaded Successfully!</span> */}
        <img className="my-3" src={photo} alt="captain-photo-uploaded" height="80" />
        <button type="button" className="btn btn-outline-secondary btn-sm mx-3"
            onClick={handleReUpload}
        >Re-Upload</button>
    </p>;

    return (
        <>
            {isSubmitting && <Loader show={isSubmitting} />}

            {optionalText ? optionalText : ""}
            <div className="form-group">
                <label>{label || 'Upload Image'}</label>
                <input type="file" className="form-control1 bg-white d-block p-2 w-100" name="image" onChange={onFileChange} />
            </div>

            <div className="d-flex align-items-center justify-content-between ">
                <p>
                    {/* <small className="d-flex">Preview</small> */}
                    <img src={previewImage || `${ABSOLUTE_ASSETS_URL}/tc-avatar.png`}
                        alt="preview-captain-image"
                        width="90" height="90" />
                    <span id="uploadingTobeText" style={{ display: 'none' }} className="text-muted">{" "} to new {" "}
                        <img id="outputImage" width="90" height="90" />
                    </span>
                </p>
                <button type="submit" className="btn btn-primary btn-sm"
                    disabled={photo === '' ? true : isSubmitting ? true : false}
                    onClick={() => onSubmit()}
                >{!isSubmitting ? 'Upload' : 'Uploading...'}</button>
            </div>

        </>
    );
}

export default UploadImage;