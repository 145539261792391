import React, { useEffect, useState } from "react";
import { useForm, useFormState } from "react-hook-form";
// import { LSKey } from "../../utlity/constants";
import { Input, Box, Button, InputLabel, FormControl, TextField } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Notify from "./Notify";
import { saveLogin } from "../utlity";

const Login = (props) => {
  let redirectTimeout;
  const SECRET_CODE = "asrx";
  const history = useHistory();

  const [isSecretMatched, setIsSecretMatched] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    // setValue,
    setError,
    formState: { errors }
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
      loginCode: ""
    }
  });

  const { isValid, isDirty } = useFormState({
    control
  });

  const onSubmit = (formData) => {
    console.log(formData);

    if (formData.loginCode && formData.loginCode === SECRET_CODE) {
      // console.log("Secret code matched!!!");
      setIsSecretMatched(true);
      //save to localStorage
      saveLogin();

      redirectTimeout = setTimeout(() => {
        history.push("/");
        window.location.href = "/";
      }, 800);
    } else {
      // console.log("Secret code not matched!!!");
      setError("loginCode", {
        type: "manual",
        message: "Secret code not matched, please try again!"
      });
      setIsSecretMatched(false);
    }
  };

  // console.log({ errors });
  // console.log("props", props);
  useEffect(() => {
    // console.log("on effect ", redirectTimeout);
    return () => {
      clearTimeout(redirectTimeout);
      console.log("on return cleanup", redirectTimeout);
    };
  });
  return (
    <section className="section-common section-login w-50 mx-auto">
      <h3>Login</h3>
      <FormControl
        component="fieldset"
        className="w-100 mt-3"
      >
        <form noValidate onSubmit={handleSubmit(onSubmit)}
          className="w-100"
        >
          {/* <Box m={1}>
            <Input
              placeholder="Email"
              inputProps={{
                "aria-label": "Enter email address",
                ...register("email", { required: true, pattern: /^\S+@\S+$/i })
              }}
            />
            {dirtyFields.email && <p>Please enter valid email address</p>}
          </Box>
          <Box m={1}>
            <Input
              placeholder="Password"
              inputProps={{
                "aria-label": "Enter password",
                ...register("password", { 
                  required: true, 
                  })
              }}
            />
            {dirtyFields.password && <p>Please enter password</p>}
          </Box> */}

          <div className="form-group">

            <InputLabel shrink htmlFor="loginCode" className="display-5">
              Password
            </InputLabel>
            <Input
              color="secondary"
              id="loginCode"
              name="loginCode"
              type="password"
              className="w-100"
              placeholder="Enter password"
              inputProps={{
                readOnly: false,
                "aria-label": "Please enter login code",
                ...register("loginCode", {
                  required: true
                })
              }}
            />

            {/* <TextField
              autoFocus
              required
              name="loginCode"
              id="loginCode"
              label="Enter password"
              color="primary"
              type="password"
              className="w-100"
              onChange={(e) => handleInput(e)}
              InputProps={{
                readOnly: false,
                "aria-label": "Please enter login code",
                ...register("loginCode", {
                  required: true
                })
              }}
            /> */}
            {/* {getValues("loginCode")} */}
            {isDirty && !isValid && (
              <Notify alertType="error" show={true} message="Please enter code" />
            )}
            {errors.loginCode && (
              <Notify
                alertType="error"
                show={true}
                message={errors.loginCode.message}
              />
            )}

          </div>

          <div className="form-group">

            <Button variant="contained" color="secondary" type="submit">
              Sign in
            </Button>
          </div>

          {isSecretMatched && (
            <Notify
              alertType="success"
              show={true}
              message={"Secret code matched!!!"}
            />
          )}
        </form>
      </FormControl>
    </section>
  );
};

export default Login;
