import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { NetworkStatus, useMutation, useQuery } from "@apollo/client";

import { Button } from "@material-ui/core";
import { Box } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
// import DeleteIcon from "@material-ui/icons/Delete";
// import BeachAccessIcon from "@material-ui/icons/BeachAccess";

import {
  QUERY_CAPTAINS,
  MUTATUON_UPDATE_CAPTAIN,
  DELETE_CAPTAIN
} from "../../service/graphql-app-crud";
import { dateInfo, getSundays, isTodaySunday, readableFormat } from "../../utlity";
import {
  deleteCaptainService,
  transformCaptains
} from "../../service/captain.service";
import { LSKey } from "../../utlity/constants";
import Back from "./Back";
import Loader from "../Loader";
import SectionTemplate from "./SectionTemplate";
import { toast } from "react-toastify";

const CaptainList = ({ deleteActiveStatus, onCaptainDeletedStatus, isLoggedIn }) => {
  // console.log("props captain list ", props);
  const history = useHistory();

  const { loading, error, data, refetch, networkStatus } = useQuery(
    QUERY_CAPTAINS,
    {
      // fetchPolicy: "no-cache", // network-only introduces a weird defect where permissions are being cached: 107847
      notifyOnNetworkStatusChange: true,
      pollInterval: 60 * 60000
    }
  );

  const [isRefreshList, setIsRefreshList] = useState(history?.location?.state?.refresh || false);

  const [updateCaptain] = useMutation(MUTATUON_UPDATE_CAPTAIN, {
    fetchPolicy: "no-cache"
  });

  const [deleteCaptain, { data: dataDelete }] = useMutation(DELETE_CAPTAIN, {
    fetchPolicy: "no-cache"
  });

  // console.log({ loading, error, data });

  const [captainData, setCaptainData] = useState({});
  const [showToday, setShowToday] = useState(isTodaySunday(getSundays()));
  const [checked, setChecked] = useState([0]);
  const [filter, setFilter] = useState('all');
  const [filteredData, setFilteredData] = useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleDelete = (captain) => {
    if (!captain.id) {
      // console.error("no id found! ");
      return;
    }
    const remainingCaptains = deleteCaptainService(captain);
    // setCaptainData(remainingCaptains);
    // console.log("remainingCaptains ", remainingCaptains);

    //run mutation method
    const id = parseInt(captain["id"]);
    deleteCaptain({
      variables: {
        id
      }
    }).then((response) => {
      if (response.data) {
        // console.log("response of delete", response.data);
        // console.log('refetching ', refetch);

        refetch();
      }
    });

    if (!remainingCaptains.length) onCaptainDeletedStatus(true);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    // console.log({ name, value });

    if (value && value !== '') {
      setFilter(value);

      const filterByData = captainData[value];
      // console.log({ value, filterByData });

      setFilteredData({
        [value]: filterByData
      });

      if (value === 'all') {
        setFilteredData({
          ...captainData
        });
      }

    }

    // console.log({ filter, captainData });
  }

  useEffect(() => {
    // console.log("data", data, {datalen: data && data.captains.length}, dataDelete, history);
    const captains = data && data.captains ? [...data.captains] : [];

    if (showToday && !window.localStorage.getItem(LSKey.ON_SUNDAY_DB_CLEAR_ONCE)) {
      // console.log('Today is sunday!');
      if (window.localStorage.getItem(LSKey.isDBUPDATE)) {
        // console.log('LS found isdbupdate');
        window.localStorage.removeItem(LSKey.isDBUPDATE);
        window.localStorage.setItem(LSKey.ON_SUNDAY_DB_CLEAR_ONCE, true);
      }
    }

    if (captains.length) {
      const transformedCaptains = transformCaptains(captains);
      setFilteredData(transformedCaptains);
      setCaptainData(transformedCaptains);
      // console.log("listing captains", transformedCaptains);

    }
  }, [data]); //important empty otherwise infinite loop

  useEffect(async () => {
    if (
      window.localStorage.getItem(LSKey.isDBUPDATE) === null ||
      !window.localStorage.getItem("isTeamCaptainDBAlreadyUpdate")
    ) {

      if (filteredData.todayCaptain && filteredData.todayCaptain.length) {
        filteredData.todayCaptain.forEach((captain) => {
          // console.log("todayCaptain database!: ", captain.onDate);
          const payload = {
            id: captain.id,
            name: captain.name,
            onDate: captain.onDate,
            already: captain.already,
            description: captain.description,
          };
          // console.log({ payload });
          invokeUpdateMethod(payload);
        });

        window.localStorage.setItem(LSKey.isDBUPDATE, true);
      }

      if (filteredData.upcomingCaptain && filteredData.upcomingCaptain.length) {
        filteredData.upcomingCaptain.forEach((captain) => {
          // console.log("updating database!: ", captain.onDate);
          const payload = {
            id: captain.id,
            name: captain.name,
            onDate: captain.onDate,
            already: captain.already,
            description: captain.description,
          };
          // console.log({ payload });
          invokeUpdateMethod(payload);
        });

        window.localStorage.setItem(LSKey.isDBUPDATE, true);
      }
    }
    return () => {
    }
  }, [filteredData.todayCaptain, filteredData.upcomingCaptain])

  useEffect(() => {
    // const { updated } = history?.location?.state;

    if (isRefreshList) {
      refetch();
    }
  }, [isRefreshList]);

  const invokeUpdateMethod = (payload) => {

    if (!Object.keys(payload).length) return;

    updateCaptain({
      variables: {
        captain: payload
      }
    }).then((response) => {
      const { data } = response;
      // console.log({ data });
    });
  }

  if (networkStatus === NetworkStatus.refetch)
    return "Refetching... To roll out again!";
  // if (loading) return <Loader show="true" message="Fetching captain's..." />;
  if (loading) return <Loader show="true" type="inplace" spinnerType="light" />;

  if (error)
    return (
      <>
        <p style={{ color: "red" }}>Error :(</p>
        <p>No captains in the list!</p>
        <p>Start with adding one</p>
        <Button size="small" variant="contained" color="primary">
          <Link to="/add" style={{ textDecoration: "none", color: "white" }}>
            Add New Captain
          </Link>
        </Button>
      </>
    );

  // const captains = data && data.captains ? data.captains : [];
  // const captainData = transformCaptains(captains);
  // console.log("listing captains", captainData);

  // console.log("listing captains", {
  //   captainData,
  //   isDBUPdate: window.localStorage.getItem(LSKey.isDBUPDATE)
  // });



  // console.log(data, data.captains);
  let sortedCaptainsData = [];
  if (data && data.captains) {
    sortedCaptainsData = data.captains.slice().sort((a, b) => a.name > b.name ? 1 : -1);
  }

  return (
    <>
      {/* <Button
        onClick={() => {
          alert('refetch'); 
        refetch()}}
        size="small"
        variant="contained"
        color="default"
      >
        Refetch Captains!
      </Button> */}

      {!deleteActiveStatus && isLoggedIn ? (
        <Box display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          paddingX={2}
          className="sticky-top actionbar-sticky-top container">
          <Button size="small" variant="contained" color="secondary">
            <Link
              to="/delete"
              style={{ textDecoration: "none", color: "white" }}
            >
              Delete Captain
            </Link>
          </Button>

          <Button size="small" variant="contained" color="primary">
            <Link to="/add" style={{ textDecoration: "none", color: "white" }}>
              Add Captain
            </Link>
          </Button>
        </Box>
      ) : null}

      {isLoggedIn && (<>
        {data && data?.captains && isLoggedIn &&
          <SectionTemplate classSuffix="login" captainData={sortedCaptainsData}
            title={"All Captain!"} handleDelete={handleDelete} deleteActiveStatus={deleteActiveStatus}
            isLoggedIn={isLoggedIn} handleToggle={handleToggle} />}

      </>)}

      {!isLoggedIn && Object.keys(filteredData).length ? (
        <>
          <Box display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            className="sticky-top actionbar-sticky-top container">
            <span className="text-light">{captainData.totalCaptains} Captain's onboard!</span>

            <Box display="flex"
              justifyContent="self-end"
              alignItems="end"
              justifySelf="end">
              <label className="d-flex d-sm-block flex-column">Filter by:{" "}
                <select className="form-select form-select-sm form-control-sm" name="filter" id="filter"
                  onChange={handleFilterChange}
                  defaultValue={filter}>
                  <option value="all">All</option>
                  {showToday && <option value="todayCaptain">Today</option>}
                  <option value="upcomingCaptain">Upcoming</option>
                  <option value="tobeCaptain">Yet Tobe</option>
                  <option value="datePassedCaptain">Date Passed</option>
                  <option value="alreadyCaptain">Already</option>
                </select>
              </label>
            </Box>
          </Box>

          {deleteActiveStatus && <h2>Delete Captain!</h2>}

          {/* <h1>Test {'sundays.includes(isTodaySunday) ' + isTodaySunday + sundays + sundays.includes(isTodaySunday)}</h1> */}

          {filteredData.todayCaptain && showToday &&
            <SectionTemplate classSuffix="today" captainData={filteredData.todayCaptain} title={"Today's Captain!"} handleDelete={handleDelete} deleteActiveStatus={deleteActiveStatus} isLoggedIn={isLoggedIn} handleToggle={handleToggle} />}

          {filteredData.upcomingCaptain &&
            <SectionTemplate classSuffix="upcoming" captainData={filteredData.upcomingCaptain} title={"Upcoming Captain!"} handleDelete={handleDelete} deleteActiveStatus={deleteActiveStatus} isLoggedIn={isLoggedIn} handleToggle={handleToggle} />}

          {filteredData.tobeCaptain &&
            <SectionTemplate classSuffix="tobe" captainData={filteredData.tobeCaptain} title={"Yet tobe Captain!"} handleDelete={handleDelete} deleteActiveStatus={deleteActiveStatus} isLoggedIn={isLoggedIn} handleToggle={handleToggle} />}

          {filteredData.alreadyCaptain &&
            <SectionTemplate classSuffix="already" captainData={filteredData.alreadyCaptain} title={"Already Captain!"} handleDelete={handleDelete} deleteActiveStatus={deleteActiveStatus} isLoggedIn={isLoggedIn} handleToggle={handleToggle} />}

          {filteredData.datePassedCaptain &&
            <SectionTemplate classSuffix="datepassed" captainData={filteredData.datePassedCaptain} title={"Date passed on Captain!"} handleDelete={handleDelete} deleteActiveStatus={deleteActiveStatus} isLoggedIn={isLoggedIn} handleToggle={handleToggle} />}

        </>
      ) : null}

      {!captainData.totalCaptains && !deleteActiveStatus ? (
        <p className="alert alert-light rounded-0">No captain found!</p>
      ) : null}

      {deleteActiveStatus ? <Back /> : null}
    </>
  );
};

export default CaptainList;
